import {
  ILessonRequest,
  LessonRequestCategory,
  TutorGenderType,
} from '@theplaza/types';
import {
  ChangeEventHandler,
  FC,
  FormEventHandler,
  useEffect,
  useState,
} from 'react';

import { CATEGORY, GENDER_TYPE } from '../../constants';
import { Button } from '../../components/Button';
import { Checkbox } from '../../components/Checkbox';
import { Icon } from '../../components/Icon';
import { Select } from '../../components/Select';
import { TextArea } from '../../components/TextArea';
import { TextField } from '../../components/TextField';
import { Toggle } from '../../components/Toggle';
import Spinner from '../../components/Spinner';
import { dataURLtoFile, isVideo } from '../../utils';
import { useUser } from '../../contexts/UserContext';

export type INewLessonRequest = Partial<
  Omit<ILessonRequest, 'id' | 'created_at' | 'updated_at' | 'requester_id'>
>;

interface IProps {
  onSubmit: (post: INewLessonRequest) => void;
  onImageChange: (image?: File) => Promise<string | undefined>;
  isImageUploading: boolean;
  hiddenAttrs?: Set<keyof INewLessonRequest>;
  disabledAttrs?: Set<keyof INewLessonRequest>;
  requiredAttrs?: Set<keyof INewLessonRequest>;
  submitButtonText?: string;
  initialValues?: INewLessonRequest;
}

const DEFAULT_REQUIRED_ATTRS = new Set<keyof INewLessonRequest>([
  'title',
  'body',
  'category',
  'region',
  'tutor_gender',
  'price',
  'private',
  'closed',
  'terms_agreed_at',
]);

const INITIAL_VALUES = {
  title: '',
  body: '',
  category: undefined,
  region: '',
  tutor_gender: undefined,
  price: '',
  private: false,
  closed: false,
  terms_agreed_at: undefined,
};

const USER_TYPE = {
  STUDENT: 0,
  TUTOR: 1,
};

const STRINGS = [
  {
    uploadOption: '(선택)',
    uploadText: '레슨 요청에 업로드할 사진/동영상을 선택해주세요.',
    priceTitle: '*희망 레슨가격(이용료 별도)',
    pricePlaceHolder: 'ex) 시간당 50,000원',
    regionTitle: '*희망 레슨지역',
    detailTitle: '*레슨이 필요하신 부분',
    detailPlaceHolder:
      'ex1) 골프 구력 5년차인데, 드라이버 비거리가 잘 늘지 않습니다. 어떻게하면 빨리 늘 수 있을까요? 레슨요청드립니다.\n\nex2) 올해안에 바디프로필 찍어보고 싶습니다. 운동/식단조절까지 모두 케어해주실 트레이너님께 레슨요청드립니다.',
    opposite: '튜터',
    autoClose: '작성하신 글은 7일 뒤 자동 마감되며,',
  },
  {
    uploadOption: '(필수)',
    uploadText: '레슨 모집에 업로드할 사진/동영상을 선택해주세요.',
    priceTitle: '*레슨가격(이용료 별도)',
    pricePlaceHolder: 'ex) 10회 1,000,000원',
    regionTitle: '*레슨 가능지역',
    detailTitle: '*모집하실 레슨 내용',
    detailPlaceHolder:
      'ex) KPGA 홍길동 프로입니다.\n- 트랙맨을 통한 정확한 분석\n- 스윙 개선 및 비거리 향상\n- 바뀐 데이터를 통한 실전 적용\n평일 월~금 오후 레슨 가능합니다.\nTPZ앱으로 1:1 대화 걸어주세요!',
    opposite: '회원',
  },
];

const THUMBNAIL_SCALE = 0.25;

const UserPostForm: FC<IProps> = ({
  onSubmit,
  onImageChange,
  isImageUploading,
  hiddenAttrs = new Set(),
  disabledAttrs = new Set(),
  requiredAttrs = DEFAULT_REQUIRED_ATTRS,
  submitButtonText = '등록하기',
  initialValues = INITIAL_VALUES,
}) => {
  const [lessonRequest, setLessonRequest] =
    useState<INewLessonRequest>(initialValues);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { isStudent } = useUser();
  const userType = isStudent ? USER_TYPE.STUDENT : USER_TYPE.TUTOR;
  let tempThumbnailVideo = document.createElement('video');

  useEffect(() => {
    if (!isStudent && lessonRequest.tutor_gender === undefined) {
      setLessonRequest({ ...lessonRequest, tutor_gender: 'all' });
    }
  }, [isStudent, lessonRequest]);

  const setLessonRequestFor =
    (
      field: keyof INewLessonRequest
    ): ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> =>
    (event) => {
      setLessonRequest({ ...lessonRequest, [field]: event.target.value });
    };

  const setLessonRequestForPrivate = (checked: boolean) => {
    setLessonRequest({ ...lessonRequest, private: checked });
  };

  const setLessonRequestForClosed = (checked: boolean) => {
    setLessonRequest({ ...lessonRequest, closed: checked });
  };

  const setLessonRequestForTermsAgreedat = (event: any) => {
    setLessonRequest({
      ...lessonRequest,
      terms_agreed_at: event.target.checked
        ? new Date().toISOString()
        : undefined,
    });
  };

  const setLessonRequestForCategory: ChangeEventHandler<HTMLSelectElement> = (
    event
  ) => {
    if (!(event.target.value in CATEGORY)) {
      setLessonRequest({ ...lessonRequest, category: undefined });
      return;
    }

    setLessonRequest({
      ...lessonRequest,
      category: event.target.value as LessonRequestCategory,
    });
  };

  const setLessonRequestForGender: ChangeEventHandler<HTMLSelectElement> = (
    event
  ) => {
    if (!(event.target.value in GENDER_TYPE)) {
      setLessonRequest({ ...lessonRequest, tutor_gender: undefined });
      return;
    }

    setLessonRequest({
      ...lessonRequest,
      tutor_gender: event.target.value as TutorGenderType,
    });
  };

  const setLessonRequestForImageUrl = async (
    image: File | null | undefined
  ) => {
    const imageUrl = await onImageChange(image || undefined);

    if (imageUrl && isVideo(imageUrl)) {
      tempThumbnailVideo.crossOrigin = 'Anonymous';
      tempThumbnailVideo.addEventListener(
        'loadeddata',
        () => {
          console.log('loadeddata');
          setLessonRequestForThumbUrl(imageUrl);
        },
        false
      );

      tempThumbnailVideo.src = `${imageUrl}#t=0.1`;
    }

    setLessonRequest({
      ...lessonRequest,
      image_url: imageUrl,
    });
  };

  const setLessonRequestForThumbUrl = async (imageUrl: string) => {
    let canvas = document.createElement('canvas');
    let context = canvas.getContext('2d');
    canvas.width = tempThumbnailVideo.videoWidth * THUMBNAIL_SCALE;
    canvas.height = tempThumbnailVideo.videoHeight * THUMBNAIL_SCALE;

    context?.drawImage(tempThumbnailVideo, 0, 0, canvas.width, canvas.height);

    const dataURI = canvas.toDataURL('image/jpeg');
    const file = dataURLtoFile(dataURI, `${+new Date()}.jpeg`);
    try {
      const thumbUrl = await onImageChange(file);
      setLessonRequest({
        ...lessonRequest,
        image_url: imageUrl,
        thumb_url: thumbUrl,
      });
    } catch (e) {
      console.log(e);
      setLessonRequest({
        ...lessonRequest,
        image_url: imageUrl,
      });
    }
  };

  const submit: FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();
    setIsSubmitting(true);

    onSubmit(lessonRequest);
  };

  const isFormValid = () => {
    let isValid = true;

    requiredAttrs.forEach((attr) => {
      if (lessonRequest[attr] === undefined || lessonRequest[attr] === '') {
        isValid = false;
      }
    });

    return isValid;
  };

  return (
    <form className="p-4 space-y-5" onSubmit={submit}>
      <div className="flex flex-col justify-between">
        <div className="mb-3">
          <div className="font-medium mb-1">
            {isStudent ? (
              STRINGS[userType].uploadOption
            ) : (
              <span className="text-red-500">
                {STRINGS[userType].uploadOption}
              </span>
            )}
            사진/동영상 업로드
          </div>
          <div className="text-gray-400 text-sm">
            {STRINGS[userType].uploadText}
          </div>
        </div>
        <label
          htmlFor="image"
          className="wh-40 relative overflow-hidden border-2 rounded-lg flex-shrink-0 flex justify-center items-center"
        >
          {!lessonRequest.image_url && (
            <div>
              <Icon.Camera className="text-gray-400 w-full mb-2" />
              <div className="text-gray-400 text-center">업로드하기</div>
            </div>
          )}
          {lessonRequest.image_url && (
            <>
              {isVideo(lessonRequest.image_url) ? (
                <div className="relative w-full">
                  <video src={`${lessonRequest.image_url}#t=0.1`} />
                </div>
              ) : (
                <img
                  className="w-full h-full absolute object-cover"
                  src={lessonRequest.image_url}
                  alt=""
                />
              )}
              <div className="filled-gray-900 absolute w-full bottom-0 text-center text-sm py-1.5 opacity-80">
                수정
              </div>
            </>
          )}
          {isImageUploading && <Spinner />}
        </label>
        <input
          type="file"
          id="image"
          className="hidden"
          accept="image/*,video/*"
          onChange={(e) => {
            const newImage = e.target.files?.item(0);

            e.target.validity.valid && setLessonRequestForImageUrl(newImage);
          }}
        />
      </div>

      <TextField
        label="*제목"
        placeholder="제목을 입력해주세요."
        className="text-sm"
        value={lessonRequest.title}
        onChange={setLessonRequestFor('title')}
      />

      <div className="flex w-full space-x-4">
        <div className="flex-1">
          <Select
            label="*종목"
            className="text-sm"
            value={lessonRequest.category}
            onChange={setLessonRequestForCategory}
          >
            <option hidden>종목 선택</option>
            {Object.keys(CATEGORY).map((category) => (
              <option key={category} value={category}>
                {CATEGORY[category as LessonRequestCategory]}
              </option>
            ))}
          </Select>
        </div>

        {isStudent && (
          <div className="flex-1">
            <Select
              label={'*희망 튜터성별'}
              className="text-sm"
              value={lessonRequest.tutor_gender}
              onChange={setLessonRequestForGender}
            >
              <option hidden>성별 선택</option>
              {Object.keys(GENDER_TYPE).map((gender) => (
                <option key={gender} value={gender}>
                  {GENDER_TYPE[gender as TutorGenderType]}
                </option>
              ))}
            </Select>
          </div>
        )}
      </div>

      <TextField
        label={STRINGS[userType].priceTitle}
        placeholder={STRINGS[userType].pricePlaceHolder}
        className="text-sm"
        value={lessonRequest.price}
        onChange={setLessonRequestFor('price')}
      />

      <TextField
        label={STRINGS[userType].regionTitle}
        placeholder="ex) 서울, 경기 부산 등"
        className="text-sm"
        value={lessonRequest.region}
        onChange={setLessonRequestFor('region')}
      />

      <TextArea
        label={STRINGS[userType].detailTitle}
        placeholder={STRINGS[userType].detailPlaceHolder}
        className="h-36 text-sm"
        value={lessonRequest.body}
        onChange={setLessonRequestFor('body')}
      />

      <div className="flex items-center space-x-2">
        <Toggle
          checked={lessonRequest.private}
          onChange={setLessonRequestForPrivate}
        />
        <div className="font-medium">비밀글 설정하기</div>
        <div className="text-xs">
          {`(*${STRINGS[userType].opposite}에게만 노출됩니다.)`}
        </div>
      </div>

      {hiddenAttrs.has('closed') ? null : (
        <div className="flex items-center space-x-2">
          <Toggle
            checked={lessonRequest.closed}
            onChange={setLessonRequestForClosed}
          />
          <div className="font-medium">글 마감하기</div>
          <div className="text-xs">(*게시글이 마감 처리 됩니다.)</div>
        </div>
      )}

      <div className="flex space-x-2">
        <Checkbox
          className={`mt-0.5 ${
            disabledAttrs.has('terms_agreed_at') ? 'opacity-60' : ''
          }`}
          required={true}
          checked={Boolean(lessonRequest.terms_agreed_at)}
          onChange={setLessonRequestForTermsAgreedat}
          id="terms-agreed-at"
          disabled={disabledAttrs.has('terms_agreed_at')}
        />
        <div>
          <label className="font-semibold" htmlFor="terms-agreed-at">
            체크사항
          </label>
          <div className="text-sm">
            {isStudent && '작성하신 글은 7일 뒤 자동 마감되며, '}
            튜터와의 레슨 및 비용 등에 대해서 TPZ스튜디오는 일체 관여하지
            않습니다. 이를 인지하고 레슨을 요청하시겠습니까? (필수)
          </div>
        </div>
      </div>

      <Button
        disabled={!isFormValid()}
        loading={isSubmitting || isImageUploading}
        text={submitButtonText}
        className="filled-brand-black w-full"
        type="submit"
      />
    </form>
  );
};

export default UserPostForm;
